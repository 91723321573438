/* 
 ! * Mobile/Other Format
*/
.project-container {
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 25px;
}

.photo-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.project-screenshot {
  width: 90%;
  height: auto;
  box-shadow: 7px 7px 5px lightgray;
}

.picture-holder {
  text-align: center;
  margin: auto;
}

.scr-shots-num-holder {
  list-style-type: none;
  justify-self: center;
  display: flex;
  margin: 10px;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
}

.scr-shot-num {
  text-align: center;
  margin: auto;
  margin: 10px;
  width: 35px;
  height: 35px;
  background-color: lightgray;
}

.active-shot {
  background-color: lightcoral;
}

.carousel-nav-btn {
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 6px;
  font-size: 20px;
  margin: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  outline: none;
  font-family: 'Nunito', 'Open Sans' ,'Roboto', 'sans-serif';
}

.tech-stack-list {
  text-align: left;
}

.site-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px;
}

.site-btn {
  margin: 10px;
  width: 130px;
  border-radius: 10px;
  border: 2px solid lightgrey;
  outline: none;
  background-color: floralwhite;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Nunito', 'Open Sans' ,'Roboto', 'sans-serif';
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  margin: 10px;
}

.site-btn:hover {
    box-shadow: 0 1px 2px rgba(188, 197, 216, 1);
    cursor: pointer;
}

.project-description {
  line-height: 1.5;
  max-width: 65ch;
  text-align: left;
  padding: 1em;
}

.tech-item {
  font-style: italic;
}

/* 
 ! * DESKTOP FORMAT
*/
@media (min-width: 1040px) {
  .project-screenshot {
    width: 85%;
    height: auto;
    /* display: flex;
    justify-self: center; */
  }

  .photo-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .carousel-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .tech-stack-list {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    max-width: 65ch;
    padding: 20px;
    list-style: circle;
  }

  .tech-item {
    /* display: flex; */
    margin: 15px;
    flex-wrap: wrap;
  }
}

