.contact-header {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

.hello-space-img {
  box-shadow: 10px 10px 5px lightgray;
  text-align: center;
  margin: auto;
  width: 300px;
  margin-top: 30px;
  height: auto;
  /* text-align: center; */
  /* margin: auto; */
}

.contact-link,
  .contact-para {
    /* display: flex; */
    flex-direction: column;
    max-width: 65ch;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
  }

  .contact-para {
    padding-left: 1em;
    padding-right: 1em;

  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@media (min-width: 1040px) {
  .contact-info {
    margin: 2em;
    margin-top: .3em;
    margin-bottom: .5em;
  }

  .contact-link,
  .contact-para {
    /* display: flex; */
    flex-direction: column;
    max-width: 65ch;
    line-height: 1.5;
  }

  .hello-space-img {
    box-shadow: 10px 10px 5px lightgray;
    text-align: center;
    margin: auto;
    width: 300px;
    margin-top: 30px;
    height: auto;
    /* text-align: center; */
    /* margin: auto; */
  }
}
