body {
  box-sizing: border-box;
  /* background-color:snow; */
  font-family: 'Nunito', 'Open Sans', 'Roboto', 'sans-serif';
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* remove gray background on button click for all buttons */
button {
  outline: none;
}

.container {
  font-size: 1.5em;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas: 'sidebar sidebar', 'content content', 'footer footer';
  grid-template-columns: 1fr;
  grid-template-rows: 80px auto 100px;
}

.footer > ul {
  list-style: none;
}

.icon {
  list-style: none;
  width: 40%;
}

.content {
  grid-area: content;
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  text-align: center;
  /* consider background url */
}

.sidebar {
  display: flex;
  position: sticky;

  top: 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(
    to right,
    rgb(85, 134, 190),
    rgb(51, 75, 119),
    rgb(44, 39, 90)
  );
  grid-area: sidebar;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 2;
}

.menu-container ul {
  list-style: none;
}

.menu-links {
  display: flex;
  /* height: 60vh; */
  justify-content: space-evenly;
  flex-direction: row;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0px;
  color: white;
  width: 100%;
  list-style: none;
}

.menu-item {
  color: white;
  text-decoration: none;
  font-weight: 700;
  opacity: 0.6;
  transition: 0.3s;
  padding: 10px;
  /* font-size: 1.2em; */
}

.current-page-nav {
  color: white;
  font-weight: 900;
  font-size: 1.1em;
  opacity: 1;
}

.menu-item:hover {
  opacity: 1;
}

.headshot {
  width: 200px;
  height: auto;
  margin-left: 40px;
  margin-bottom: 15px;
  margin-right: 50px;
}

.tagline {
  font-size: 2.5em;
  margin-bottom: 0;
  font-weight: bold;
}

.vocation {
  margin-top: 0.5em;
  font-weight: 600;
}

.welcome-text {
  font-size: 1.2em;
  font-weight: 300;
}

.summary {
  font-weight: 300;
  line-height: 1.6em;
}

.action-link-text {
  font-family: 'Arial', 'Open Sans', 'Nunito', 'Roboto', 'sans-serif';
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  background: rgb(85, 134, 190);
  padding: 0.5em 2em;
  border-radius: 40px;
  margin: 1.3em 0;
  transition: 0.3s;
  border: none;
}

.action-link-text:hover {
box-shadow: 0 10px 50px rgba(188, 197, 216, 1);
cursor: pointer;
}
/* 
.call-action {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 1.3em;
  background: rgb(85, 134, 190);
  padding: 0.5em 2em;
  border-radius: 40px;
  margin: 1em 0;
  transition: 0.3s;
  border: none;
}

.call-action:hover {
  box-shadow: 0 10px 50px rgba(188, 197, 216, 1);
  cursor: pointer;
} */

/* 
 TODO
 portfolio pane 
*/
.barometer-screenshot {
  min-width: 260px;
  /* max-width: 780px; */
  height: auto;
  width: 90%;
}

/* .project-screenshot:click {
  min-width: 260px;
  max-width: 780px;
  height: auto;
  width: 90%;
} */

.footer {
  grid-area: footer;
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

.footer-items > ul {
  display: flex;
  justify-content: space-evenly;
  max-width: 640px;
  margin: 1.2em auto;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.footer-items {
  list-style: none;
  align-self: flex-end;
}

main {
  background-color: #f7f7f7;
}

.footer-items {
  background-color: white;
}

.content,
.menu-container,
.footer {
  padding: 15px;
}

/* 
 * For now, a hacky precedence fix for having padding-top come after
 * the other comprehensive mobile 15px padding above, to  only customize menu padding top
*/
.menu-container {
  padding-top: 1em;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 60%;
  /* background: linear-gradient(to right, 
  rgb(85, 134, 190),
  rgb(51, 75, 119),
  rgb(44, 39, 90)); */
  justify-content: space-evenly;
  align-items: center;
}

/* Contact formatting */
.contact-info {
  display: flex;
  flex-direction: column;
}

.message-area {
  display: flex;
  flex-direction: column;
}

.menu {
  grid-area: menu-container;
  align-self: stretch;
  /* padding: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/*
 ! * DESKTOP FORMAT
*/
@media (min-width: 1040px) {
  .about-info {
    margin-left: 50px;
  }

  .container {
    grid-template-areas:
      'sidebar content'
      'sidebar footer';
    grid-template-columns: 200px 1fr;
    grid-template-rows: 1fr auto;
  }

  .content,
  .menu-container,
  .footer {
    padding: 1em;
  }

  .menu {
    grid-area: menu-container;
    align-self: stretch;
    margin-top: 120px;
    height: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .content {
    grid-area: content;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: center;
    /* consider background url */
  }

  .sidebar {
    background: linear-gradient(
      to bottom,
      rgb(85, 134, 190),
      rgb(51, 75, 119),
      rgb(44, 39, 90)
    );
    grid-area: sidebar;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    justify-content: center;
  }

  .footer {
    grid-area: footer;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .menu-links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* padding: 20px; */
    width: 100%;
  }

  .menu-container {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    width: 60%;
    /* background: linear-gradient(to right, 
    rgb(85, 134, 190),
    rgb(51, 75, 119),
    rgb(44, 39, 90)); */
    justify-content: space-evenly;
    align-items: center;
  }

  .content {
    text-align: left;
    padding: 3em;
    padding-top: 1.5em;
  }

  .headshot {
    width: 250px;
    height: auto;
    float: right;
  }

  .tagline {
    font-size: 2.5em;
    margin-bottom: 0;
    font-weight: bold;
  }

  .summary {
    width: 60%;
  }

  .footer-items {
    /* max-width: 960px; */
    /* margin: 0 auto; */
    /* padding: 2em 0; */
    margin: 1.5em;
    margin-bottom: 0;
  }

  .icon {
    width: 40%;
  }
}
